import { Container, Stack, Col, Row } from "react-bootstrap";
import style from "./Home.module.css";
import building from "../../constant/buildingImg.json";
import content from "../../constant/content.json";
import professor from "../../constant/professor.json";


const Home = () => {
    return <>
    <Container>
        <div className={`${style.heading_flex}`}>
            {/* <img src="img_foot.png" alt="" /> */}
        <Stack className={`${style.heading_flex_child}`}>
        <h1>ANNUAL STATE CONFERENCE</h1>
        
        
        <Stack className={`${style.heading_flex_child2}`}><p>Live Diabetic Foot Workshop & Certificate Course</p></Stack>
        </Stack>
        </div>
        <Container className={`${style.subheading_flex}`}>
        <Row className={`${style.subheading}`}>
            <Col lg={6} sm={12} style={{padding:"20px"}}><b>3rd - 4th February 2024</b></Col>
            <Col lg={6} sm={12} style={{backgroundColor:"#fff", padding:"20px 100px 20px 20px" , }}><img src="location_img.png" alt="img" /><span style={{marginLeft: "10px"}}> KIMS Hospital, Secunderabad</span></Col>
        </Row>
        </Container>
        <Row className={`${style.image_flex}`} >
            {building.map((item) => (
            <Col lg={4} className={`${style.image_flex_child}`} >
            <img src={item.img} alt="" width={360} />
            </Col>
               )) }
        </Row>
        <Container>
            <div className={`${style.map_text}`}><h1>KIMS | INSTITUTE OF VASCULAR SCIENCES</h1>
            <h4>Hyderabad</h4>
            </div>
            <div className={`${style.map_flex}`}>
                <Stack className={`${style.map_flex_child}`}>
                    <img src="/map_img.png" alt="" />
                </Stack>
            </div>
        </Container>
        <Container className={`${style.content_flex}`}>
            <div className={`${style.content_flex_child}`}><h1>Workshop Highlight</h1></div>
            {content.map((item) => (
                <div className={`${style.content_flex_child2}`}>* {" "}{item.highlight}</div>
            ))}
        </Container>
        <Container>
        <div className={`${style.professor_flex}`}>
        <div className={`${style.content_flex_child}`}><h1>IPACON-2024, NATIONAL BODY</h1></div>
                <Row className={`${style.image_flex}`}>
               {professor?.national_body?.map((item) => ( <Col className={`${style.image_flex_child}`} lg={4}>
                    <img src={item.img} alt="" width={360} height={280}/>
                    <h3>{item.name}</h3>
                    <p>{item.position}</p>
                </Col> ))}
                </Row>
            </div>
        </Container>
        <Container>
        <div className={`${style.professor_flex}`}>
        <div className={`${style.content_flex_child}`}><h1>IPACON-2024, STATE BODY</h1></div>
                <Row className={`${style.image_flex}`}>
               {professor?.state_body?.map((item) => ( <Col className={`${style.image_flex_child}`} lg={4}>
                    <img src={item.img} alt="" width={360} height={280}/>
                    <h3>{item.name}</h3>
                    <p>{item.position}</p>
                    <p>{item.city}</p>
                </Col> ))}
                </Row>
            </div>
        </Container>
        <Container>
        <div className={`${style.professor_flex}`}>
        <div className={`${style.content_flex_child}`}><h1>FACULTY @ IPACON-2024</h1></div>
                <Row className={`${style.image_flex}`}>
               {professor?.faculty?.map((item) => ( <Col className={`${style.image_flex_child}`} lg={4}>
                    <img src={item.img} alt="" width={360} height={280}/>
                    <h3>{item.name}</h3>
                    <p>{item.position}</p>
                    <p>{item.city}</p>
                </Col> ))}
                </Row>
            </div>
        </Container>
        <Container>
        <div className={`${style.content_flex}`}>
        <div className={`${style.content_flex_child}`}><h1>Registration Form</h1></div>
            <div className={`${style.form_title}`}>
               <label>Title:</label>
               <input type="checkbox" /><label>prof.</label>
               <input type="checkbox"  /><label>Dr.</label>
               <input type="checkbox" /><label>Mr.</label>
               <input type="checkbox" /><label>Ms.</label>
               <div><label>IPA Life Membership</label>
               <input  type="text" />
               </div>
            </div>
            <div className={`${style.form_name}`}>
                <label>Full Name:
                </label>
                <input type="text" />
            </div>
            
                <div className={`${style.form_gender}`}>
                    <label>Gender:
                    </label>
                    <input type="text" />
                </div>
                <div className={`${style.form_email}`}>
                    <label>Email:
                    </label>
                    <input type="text" />
                </div>
            
            <div className={`${style.form_address}`}>
                <label>Address:
                </label>
                <input type="text" />
            </div>
            
                <div className={`${style.form_city}`}>
                <label>City:
                </label>
                <input type="text" />
                </div>
                <div className={`${style.form_state}`}>
                <label>State:
                </label>
                <input type="text" />
                </div>
            
            
                <div className={`${style.form_country}`}>
                <label>Country:
                </label>
                <input type="text" />
                </div>
                <div className={`${style.form_pincode}`}>
                <label>Pin Code:
                </label>
                <input type="text" />
                </div>
            
        
                <div className={`${style.form_mobile}`}>
                <label>Mobile/ Whatsapp:
                </label>
                <input type="text" />
                </div>
                <div className={`${style.form_ipa}`}>
                <label>IPA No.:
                </label>
                <input type="text" />
                </div>
            <div className={`${style.form_btn}`}>
            <button >Submit</button>
            </div>
            </div>
        </Container>
        <Container>
        <div className={`${style.professor_flex}`}>
        <div className={`${style.content_flex_child}`}>
            <h1>Live Workshop/Certificate Course & Complimentary Lifetime Membership</h1>
            </div>
                
            </div>
            <div className={`${style.professor_flex}`}>
        <div className={`${style.content_flex_child}`}>
            <h1>Non-Members</h1>
            </div>
            <div>
                <h1>Rs18,000/- + GSt%</h1>
                </div>
                <div className={`${style.content_flex_child}`}>
            <h1>Members</h1>
            </div>
            <div>
                <h1>Rs10,000/- + GSt%</h1>
                </div>
                <div className={`${style.content_flex_child}`}>
            <h1>Students</h1>
            </div>
            <div>
                <h1>Rs10,000/- + GSt%</h1>
                </div>
            </div>

        </Container>
        <Container className={`${style.new_year}`}>
        <div className={`${style.new_year_flex}`}>
        <div className={`${style.new_year_flex_child}`}><h1>NEW YEAR OFFER</h1></div>
        <div className={`${style.new_year_flex_child2}`}><h3>Complimentary Lifetime Membership for NON-MEMBERS who register before Jan 17th 2024</h3></div> 
            </div>
            
        </Container>

    </Container>
    </>
}

export default Home;
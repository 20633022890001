
import Home from "../components/Home/Home";
import Footer from "../components/Footer/Footer"

const HomePage = () => {
    return <>
    <Home />
    <Footer />
    </>
}

export default HomePage;
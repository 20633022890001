import {Link} from "react-router-dom";
import React from "react";
import style from "./Header.module.css"
import { Container, Nav , Navbar } from "react-bootstrap";


const Header = () => {
    return <>
     <Container style={{position:"fixed", width:"100%", zIndex:"3", top:"0"}}>
      <Navbar expand="lg" className="bg-body-tertiary" fixed="top" style={{padding:" 10px 30px" , background : 'brown', }} >
        <Container style={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
          
          <Navbar.Brand href="#"><Link href="/" className="purple_lgo"><img width={100} alt= ""  src="ipa_logo.png" /></Link></Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="mx-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
           <div className={`${style.header_flex}`}><h1>Indian Podiatry Association</h1>
           <p>TS/AP Chapter</p>
           </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Container>
    </>
}

export default Header;
import {Row, Col, Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import { FaFacebookF, FaLinkedin} from "react-icons/fa";
import style from "./Footer.module.css";

const Footer = () => {
    return (
        <>
        {/* <footer className="text-left text-lg-start footer-wrapper mt-5">
      <section className=""> */}
        <Container className={`${style.footer_flex}`}>
          <div className={`${style.footer_flex_child}`}>
            
              <h6 className="text-uppercase fw-bold mb-4">
                <Link href="/" className="purple_lgo">
                  <img alt="" src="ipa_logo.png" />
                </Link>
              </h6>
              <h3>IPA-Hyderabad Office</h3>
              <p>
            
            ADVANCED WOUND HEALING CLINICS 
            
              </p>
            </div>
        
         


            <div className={`${style.footer_flex_child2}`}>
              <h3 className="text-uppercase fw-bold mb-4">Contact</h3>
              <p>
                
                #16-11-310/14/6/3, Saleem Nagar, Karchi Barkery Lane,
                Moosarambagh Metrostation, Pillar No. 1486, Hyderabad
              </p>
              <div>
                <h3>Phone</h3>
               <p>9054-108-789</p> 
              </div>
              <p>dr.santoshmurthy@gmail.com</p>
            </div>
          
        </Container>
      {/* </section>
      <section
        className="d-flex justify-content-center justify-content-lg-between border-top pt-4"
       
      >

        <div>
          <a href="https://www.facebook.com" className="me-4 text-reset">
            <FaFacebookF />
          </a>
          <a href="https://www.linkedin.com" className="me-4 text-reset">
            <FaLinkedin />
          </a>
        </div>
      </section>
    </footer> */}
        </>
    )
}

export default Footer;